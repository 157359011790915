.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  min-width: 440px;
  min-height: 400px;
}

.modal-header,
.modal-footer {
  padding: 10px 0;
}

.modal-body {
  padding: 20px 0;
}



.input-validation-container {
  font-family: Arial, sans-serif;
  max-width: 300px;
  margin: auto;
}

.message {
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.valid {
  color: #28a745;
  border-color: #28a745;
}

.invalid {
  color: #dc3545;
  border-color: #dc3545;
}

.general-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.general-input.valid {
  border: 1px solid #28a745;
}

.general-input.invalid {
  border: 1px solid #dc3545;
}


/* aqui */
.input-container {
  position: relative;
  padding-top: 20px;
  font-family: Arial, sans-serif;
}

.input-label {
  top: 0;
  left: 0;
  color: #757575;
  transition: all 0.2s ease;
  pointer-events: none;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.2s;
}

.input-field:focus {
  outline: none;
  border-color: #757575;
}

.input-field:focus + .input-label,
.input-field.focused + .input-label,
.input-label.focused {
  top: -20px;
  left: 0;
  font-size: 17px;
  color: #757575;
}


/* INPUT DATE*/
.date-input::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  background: none;
  z-index: 1;
}

.date-input::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

.date-input::-webkit-datetime-edit-text {
  padding: 0 0.3em;
}

.date-input::-webkit-datetime-edit-month-field,
.date-input::-webkit-datetime-edit-day-field,
.date-input::-webkit-datetime-edit-year-field {
  padding: 0 0.3em;
}

.date-input::-webkit-inner-spin-button {
  display: none;
}

.date-input::-webkit-calendar-picker-indicator {
  /* background: url('calendar-icon.svg') no-repeat; */
  background-size: 1.2em;
  cursor: pointer;
  opacity: 0.6;
  filter: invert(50%);
}

.date-input::-ms-clear {
  display: none;
}

.input-content {
  margin: 10px;
}



.button-container {
  display: flex;
  justify-content: right;
}

.cancel-button {
  padding: 10px 20px;
  border: none;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 130px;
  background-color: #dc3545; /* Bootstrap 'danger' color */
  color: white;
}

.cancel-button:hover {
  background-color: #c82333; /* A slightly darker shade for hover effect */
}

.save-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 130px;
  background-color: #28a745; /* Bootstrap 'success' color */
  color: white;
}

.save-button:hover {
  background-color: #218838; /* A slightly darker shade for hover effect */
}

