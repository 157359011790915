/* TabsComponent.css */
.tabs {
    display: flex;
    gap: 10px;
    margin-top: 100px;
  }
  
  .inactiveTab {
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    border-radius: 10px;
    font-weight: 600;
    border: 2px #07e9d1 solid;
  }
  
  .activeTab {
    padding: 10px;
    font-size: 20px;
    font-weight: 600;
    background-color: #07e9d1;
    color: white;
    border-radius: 10px;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 10px;
  }
  
  .grid-item {
    padding: 20px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .tab-content p {
    margin-top: 10px;
    color: gray;
    font-style: italic;
  }
  